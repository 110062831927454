









































































import { Component, Vue } from "vue-property-decorator";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

@Component({
  name: "Cover",
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  }
})
export default class extends Vue {
  public swiperOptions = {
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    loop: true,
    initialSlide: 0,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    speed: 1500,
    grabCursor: true
  };
  private duration = 3000;
  private speed = 0.5;
  private cursorXOffset = 0;
  private cursorYOffset = -5;
  private hearts: HTMLDivElement[] = [];
}
