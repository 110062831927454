
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import axios from "axios";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const nl = require("vee-validate/dist/locale/nl.json");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const en = require("vee-validate/dist/locale/en.json");
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

@Component
export default class InteresseFormulier extends Vue {
  public dutch = false;
  public english = false;
  public JeugdOfOther = "";
  public OtherLevel = "";
  public OtherPosition = "";
  public OtherFase = "";
  public OtherSchool = "";
  public OtherAvailability = "";
  public signedup = false;

  public form = {
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    phone: "",
    accepted: false,
    trainingsDates: {
      Aug24: false,
      Aug25: false,
      Aug27: false,
      Aug31: false,
      Sep1: false,
      Sep2: false,
      Sep7: false,
      Sep8: false,
      Sep9: false
    },
    experience: {
      yes: 0,
      association: "",
      years: 0,
      lastTime: 0,
      when: null,
      level: null,
      position: null
    },
    study: {
      fase: "",
      school: "",
      hasTime: ""
    },
    remarks: ""
  };

  public goDutch() {
    localize("nl", nl);
    this.dutch = true;
  }

  public goEnglish() {
    localize("en", en);
    this.english = true;
  }

  public getAccepted(state: any) {
    return state;
  }

  mounted() {
    if (window.location.pathname.includes("nl")) this.goDutch();
    else if (window.location.pathname.includes("en")) this.goEnglish();

    this.form.email = this.$route.query.email as string;
    this.form.firstName = this.$route.query.firstName as string;
    this.form.lastName = this.$route.query.lastName as string;
    this.form.phone = this.$route.query.phone as string;
  }

  getValidationState({ dirty, validated, valid = null }) {
    return dirty || validated ? valid : null;
  }

  async onSubmit() {
    if (this.form.study.hasTime === "other")
      this.form.study.hasTime = this.OtherAvailability;
    if (this.form.experience.when === "other")
      this.form.experience.when = this.JeugdOfOther;
    if (this.form.experience.level === "other")
      this.form.experience.level = this.OtherLevel;
    if (this.form.experience.position === "other")
      this.form.experience.level = this.OtherPosition;
    if (this.form.study.fase === "other") this.form.study.fase = this.OtherFase;
    if (this.form.study.school === "other")
      this.form.study.school = this.OtherSchool;

    await axios.post(
      "https://send.pageclip.co/UCrx17eaLM39T1RSRqC7ZNogzoucm07P/interesseform",
      this.form,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    this.signedup = true;
  }
}
