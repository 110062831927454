


















































































































































































































import { Component, Vue } from "vue-property-decorator";
import Collapse from "./components/Collapse.vue";

@Component({
  components: {
    Collapse
  }
})
export default class FAQ extends Vue {}
