





































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Statistics extends Vue {}
