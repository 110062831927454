







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Collapse extends Vue {
  @Prop({ required: true }) public title!: string;
  @Prop({ required: true }) public description!: string;

  public collapsed = false;

  get style() {
    return ["accordion-header", this.collapsed ? "active" : ""];
  }
}
