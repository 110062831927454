


















import { Component, Vue } from "vue-property-decorator";
import Cover from "@/components/Cover.vue";
import Overlay from "@/components/Overlay.vue";
import Statistics from "@/components/Statistics.vue";
import Intresselijst from "@/components/Intresselijst.vue";
import VideoEmbedded from "@/components/Video.vue";
import Programma from "@/components/Programma.vue";
import Oweeprogramma from "@/components/Oweeprogramma.vue";
import Volleybal from "@/components/Volleybal.vue";
import Vereniging from "@/components/Vereniging.vue";
import FAQ from "@/components/FAQ.vue";
import NavBar from "@/Layout/NavBar.vue";
import Footer from "@/Layout/Footer.vue";
import Sponsors from "@/components/Sponsors.vue";

@Component({
  components: {
    FAQ,
    Vereniging,
    VideoEmbedded,
    Oweeprogramma,
    //Programma,
    Volleybal,
    Intresselijst,
    Overlay,
    Cover,
    Statistics,
    Sponsors,
    Footer,
    NavBar
  }
})
export default class Home extends Vue {}
