









































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "NavBar"
})
export default class extends Vue {
  public showNavbar = false;

  created() {
    window.addEventListener("resize", this.checkResize);
  }

  checkResize() {
    if (window.innerWidth > 1000) {
      this.setShowNavFalse();
    }
  }

  switchLanguage(lang) {
    localStorage.setItem("lang", lang);
  }

  get navbarClasses() {
    return ["navlinks", this.showNavbar ? "opened" : ""];
  }

  public setShowNavFalse() {
    this.showNavbar = false;
    document.documentElement.style.overflow = "auto";
  }

  public showNavbarToggle() {
    this.showNavbar = !this.showNavbar;
    if (this.showNavbar) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }
}
