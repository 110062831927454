import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import AOS from "aos";
import VModal from "vue-js-modal";
import "aos/dist/aos.css";
import router from "./router";

Vue.config.productionTip = false;
Vue.use(VModal, { dialog: true });
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

if (localStorage.getItem("lang") == null) {
  localStorage.setItem("lang", "NL");
  Vue.prototype.$lang = "NL";
} else {
  Vue.prototype.$lang = localStorage.getItem("lang");
}

new Vue({
  router,
  created() {
    AOS.init({
      once: true
    });
  },
  render: h => h(App)
}).$mount("#app");
