




































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Intresselijst extends Vue {
  public phone = "";
  public firstName = "";
  public lastName = "";
  public email = "";
  public sent = false;

  public lang = localStorage.getItem("lang");

  public doSendInfo() {
    window.location.href =
      "https://owee.punch.tudelft.nl/interesse/nl/?firstName=" +
      this.firstName +
      "&lastName=" +
      this.lastName +
      "&email=" +
      this.email +
      "&phone=" +
      this.phone;
  }
}
