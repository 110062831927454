import { render, staticRenderFns } from "./InteresseFormulier.vue?vue&type=template&id=156df3b0&scoped=true&"
import script from "./InteresseFormulier.vue?vue&type=script&lang=ts&"
export * from "./InteresseFormulier.vue?vue&type=script&lang=ts&"
import style0 from "./InteresseFormulier.vue?vue&type=style&index=0&id=156df3b0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "156df3b0",
  null
  
)

export default component.exports