import Vue from "vue";
import Router from "vue-router";
import Home from "@/components/Home.vue";
import InteresseFormulier from "@/components/InteresseFormulier.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/interesse*",
      name: "Interesse",
      component: InteresseFormulier
    },
    {
      path: "/",
      name: "Home",
      component: Home
    }
  ]
});
