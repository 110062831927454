













import { Component, Vue } from "vue-property-decorator";

@Component
export default class Video extends Vue {
  frameHeight = window.innerWidth * 0.56;
  frameWidth = window.innerWidth;
}
