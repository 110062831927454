<!--<template>
  <!-- Dosign sponsoring logo -->
  <a
    href="https://www.dosign.com/nl-nl/carriere/dosign-students/?utm_source=SV&utm_medium=link&utm_campaign=Punch+_Students_paid"
    target="_blank"
  >
    <img class="dosignLogo" alt="" src="../assets/images/dosign_logo.png" />
  </a>
</template> -->

<script>
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Sponsors extends Vue {}
</script>

<style scoped>
.dosignLogo {
  max-width: 25%;
  margin-top: 50px;
  margin-bottom: 20px;
}
</style>
