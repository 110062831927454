


































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Vereniging extends Vue {
  public modal = {
    height: window.innerHeight > 500 ? window.innerHeight * 0.8 : 500,
    width: window.innerWidth * 0.9
  };
}
